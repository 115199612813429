@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(./fonts/Quicksand-VariableFont_wght.ttf) format('truetype');
  }

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
  }

/* Reduce font size for all MUI inputs, labels, and selects */
.MuiInputBase-root {
  font-size: 0.875rem !important;
}

.MuiAutocomplete-option {
  font-size: 0.875rem !important;
}

.MuiAutocomplete-listbox {
  font-size: 0.875rem !important;
}

.MuiInputLabel-root {
  font-size: 0.875rem !important;
}

.MuiSelect-select {
  font-size: 0.875rem !important;
}

.MuiMenuItem-root {
  font-size: 0.875rem !important;
}